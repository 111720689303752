<template>
  <div class="passWordSetBox">
    <p class="passwordSetTitle">密码设置</p>
    <p class="c_00 f16 mt30">
      请设置您的权限密码：权限密码用于进入个人中心需要权限的栏目使用
    </p>
    <el-form
      :inline="true"
      :model="passwordForm"
      :rules="rules1"
      class="demo-form-inline"
      ref="passwordForm"
      style="margin-top: 0.3rem"
    >
      <el-form-item label="密  码" prop="password">
        <el-input
          class="passInput"
          v-model="passwordForm.password"
          placeholder="请输入您要设置的权限密码"
          v-if="resetPassword"
        >
        </el-input>
        <p v-else style="width: 2.89rem; text-align: center">
          {{ secret || "暂无密码" }}
        </p>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          @click="resetPassword = true"
          v-if="!resetPassword"
          >重置密码</el-button
        >
        <el-button
          size="mini"
          type="primary"
          v-else
          @click="submitUpdateSecret('passwordForm')"
          >确认密码</el-button
        >
      </el-form-item>
    </el-form>
    <el-form
      :model="passwordSetForm"
      ref="passwordSetForm"
      label-width="0.8rem"
      class="demo-ruleForm"
    >
      <el-form-item
        label="选择需要设置密码的栏目"
        class="selectMenu mt40"
      >
        <el-checkbox-group v-model="passwordSetForm.menu">
          <el-checkbox
            :label="item.id"
            v-for="(item, index) in routeList"
            :key="index"
            :checked ='item.isSet'
          >{{item.title}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button type="primary" @click="submitForm('passwordSetForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import { columnListNew, getSecret, updateSecret,updateColumnAuth } from "../../Api";
export default {
  name: "MyPassWordSet",
  data() {
    return {
      secret: "",
      resetPassword: false,
      routeList: [],
      //密码设置表单
      passwordSetForm: {
        menu: [],
      },
      passwordForm: {
        password: "",
      },
      rules1: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    columnListNew(getToken()).then((res) => {
      this.routeList = res.data;
    });
    getSecret(getToken()).then((res) => {
      if (res.data.secret) this.secret = res.data.secret;
    });
  },
  methods: {
    submitUpdateSecret(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateSecret({
            token: getToken(),
            secret: this.passwordForm.password,
          }).then((res) => {
            if (res.data.message == "setSuccess") {
              this.resetPassword = false;
              this.secret = this.passwordForm.password;
            }else{
              this.$message.error("设置密码失败,请检查密码是否与原密码相同");
            }
          });
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.secret) {
            this.$message.error("请先设置密码");
          }
          updateColumnAuth({
            id: this.passwordSetForm.menu.join(","),
            token: getToken(),
          }).then((res) => {
            if (res.data.message == "setSuccess") {
              this.$message.success("保存成功");
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-button--primary {
  width: 1.5rem;
  background-color: #f46600;
  border-color: #f46600;
}
::v-deep .el-form-item {
  margin-bottom: 0.3rem;
}
::v-deep .el-checkbox-group {
  width: 8rem;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f46600;
  border-color: #f46600;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f46600;
}
.selectMenu >>> .el-form-item__label {
  float: none;
}
.selectMenu >>> .el-form-item__content {
  margin-left: 0 !important;
  margin-top: 0.1rem;
}
.passInput {
  width: 2.9rem;
}
::v-deep .el-form-item__label {
  text-align: left;
  line-height: 0.38rem;
}
::v-deep .el-form-item__content {
  line-height: 0.38rem;
  font-size: 0.14rem;
}
::v-deep .el-input__inner {
  height: 0.38rem;
  line-height: 0.38rem;
  font-size: 0.14rem;
}
.demo-ruleForm {
  width: 5.5rem;
  margin-top: 0.3rem;
}
.passwordSetTitle {
  color: #040404;
  font-size: 0.2rem;
  font-weight: bold;
  padding-bottom: 0.2rem;
  border-bottom: 0.02rem solid #eeeeee;
}
.passWordSetBox {
  background: #ffffff;
  padding: 0.3rem;
  border-radius: 0.04rem;
}
</style>